import React, { useEffect } from 'react';
import { ReviewService } from '../../review/services/review.service';


export const AdmminBlunderReviewPage = props => {

  const handleRedirection = async (id) => {
    const response = await ReviewService.getBlunderForAdmin(id);
    if(response) {
      props.history.replace('/blunder/review/' + response)
    }
  }

  useEffect(() => {
    const id = props.match.params.id;
    if(id) {
      handleRedirection(id);
    } else {
    }
  }, [])

  return null;
}


export default AdmminBlunderReviewPage;
