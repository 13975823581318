import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import { withStyles } from "@material-ui/core/styles";
import {
  PreviousButton,
  PlayButton,
  NextButton,
  PauseButton,
} from "./buttons/moves.buttons";

let eventListener = null;

const BorderlessTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: 0,
  },
})(TableCell);

const initialState = {
  mouseX: null,
  mouseY: null,
};

let playTimeout = null;

const MovesPanel = (props) => {
  const [autoplay, setAutoplay] = useState(false);
  const [state, setState] = React.useState(initialState);

  const { selectedMove } = props;

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "ArrowUp") {
        props.onMoveClick(props.selectedMove - 2);
        e.preventDefault();
      }
      if (e.key === "ArrowDown") {
        props.onMoveClick(props.selectedMove + 2);
        e.preventDefault();
      }
      if (e.key === "ArrowLeft") {
        props.onMoveClick(props.selectedMove - 1);
        e.preventDefault();
      }
      if (e.key === "ArrowRight") {
        props.onMoveClick(props.selectedMove + 1);
        e.preventDefault();
      }
    };

    // Add keydown listener
    document.addEventListener("keydown", onKeyDown);

    return () => {
      // Cleanup
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [selectedMove]);

  const test = (increment) => {
    props.onMoveClick(props.selectedMove + increment);
  };

  const openContextMenu = (event, move) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      selectedMove: move,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };

  const deleteAllAfter = (move) => {
    props.deleteAfter(move);
    handleClose();
  };

  const previousMove = () => {
    if (props.selectedMove > 0) {
      props.onMoveClick(props.selectedMove - 1);
    }
  };

  const nextMove = () => {
    if (props.selectedMove < props.moves.length - 1) {
      props.onMoveClick(props.selectedMove + 1);
    }
  };

  const play = (selectedMove) => {
    if (selectedMove >= props.moves.length - 1) {
      setAutoplay(false);
      return;
    }

    props.onMoveClick(selectedMove + 1);
    playTimeout = setTimeout(() => {
      play(selectedMove + 1);
    }, 2000);
  };

  const startPlay = () => {
    setAutoplay(true);
    play(props.selectedMove);
  };

  const stopPlay = () => {
    setAutoplay(false);
    if (playTimeout) {
      clearTimeout(playTimeout);
    }
  };

  const goToLastMove = () => {
    props.onMoveClick(props.moves.length - 1);
  };

  const _getInPairs = (moves) => {
    const pairedMoves = [];

    let finalMoves = [...moves]
    if(props.startTurn === 'b') {
      finalMoves = [ {
          disabled: true,
          san: '...'
      }, ...finalMoves];
    }

    finalMoves.reduce(function (result, value, index, array) {
      if (index % 2 === 0)
        pairedMoves.push(
          array.slice(index, index + 2).map((x, ind) => ({
            index: index + (props.startTurn === 'b' ? ind - 1 : ind),
            move: x,
          }))
        );
      return result;
    }, []);

    return pairedMoves;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Scrollbars
          style={{ flexGrow: 1 }}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{ ...style, backgroundColor: "#4C4C4C", borderRadius: 2 }}
            />
          )}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                height: "100%",
                top: 0,
                right: 0,
                padding: "0 2px",
                backgroundColor: "#000",
                opacity: 0.1,
              }}
            />
          )}
        >
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {_getInPairs(props.moves).map((rows, index) => (
                  <TableRow key={index}>
                    <BorderlessTableCell
                      align="left"
                      style={{
                        width: 25,
                        fontSize: 12,
                        opacity: 0.3,
                        color: "#fff",
                        padding: "5px 10px",
                      }}
                    >
                      {index + 1}
                    </BorderlessTableCell>
                    {rows.map((row, index2) => (
                      <BorderlessTableCell align="left">
                        <div
                          style={{
                            borderRadius: 4,
                            opacity:
                              props.selectedMove > row.index - 1 ? 1 : 0.5,
                            backgroundColor:
                              props.selectedMove == row.index
                                ? "#503EC4"
                                : "transparent",
                          }}
                        >
                          <button
                            onContextMenu={(e) => openContextMenu(e, row)}
                            onClick={(e) => props.onMoveClick(row.index)}
                            color={
                              props.selectedMove == row.index
                                ? "primary"
                                : "default"
                            }
                            variant={
                              props.selectedMove == row.index
                                ? "contained"
                                : "text"
                            }
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              border: "none",
                              outline: "none",
                              display: "block",
                              padding: 5,
                              textDecoration: "none",
                              color: "white",
                              fontSize: 14,
                            }}
                            disabled={row.move.disabled}
                          >
                            {row.move.san}
                          </button>
                        </div>
                      </BorderlessTableCell>
                    ))}
                    {rows.length === 1 ? <BorderlessTableCell /> : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbars>
        <Button color="primary" variant="contained" onClick={() => props.copyPgn() }>
          Get PGN
        </Button>
        <div
          style={{
            height: 60,
            background: "#1E1E1E",
            boxShadow: "0px -14px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: 6,
            transform: "matrix(1, 0, 0, -1, 0, 0)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <PreviousButton onClick={() => previousMove()} />
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {autoplay ? (
              <PauseButton onClick={() => stopPlay()} />
            ) : (
              <PlayButton onClick={() => goToLastMove()} />
            )}
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <NextButton onClick={() => nextMove()} />
          </div>
        </div>
      </div>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => deleteAllAfter(state.selectedMove)}>
          Delete all moves after
        </MenuItem>
      </Menu>
    </>
  );
};

export default MovesPanel;
