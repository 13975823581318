import React, { useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  LinearProgress,
  MenuItem,
  InputLabel,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { CrudTable } from "../../../ui/components/tables/CrudTable";
import { Formik, Form, Field } from "formik";
import { Select, TextField as FormikTextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import ApiService from "../../../domain/services/ApiService";
import { useSnackbar } from "notistack";

const BrowseUsers = (props) => {
  const tableRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [refresh, setRefresh] = useState(1);

  const assignToUser = (user) => {
    setCurrentUser(user);
    setOpen(true);
  };

  return (
    <>
      <CrudTable
        refresh={refresh}
        resource={"users"}
        columns={[
          // ["ID", "id"],
          ["Email", "email"],
          ["Role", "role"],
          ["Blunders assigned", "blundersAssigned"],
          ["Blunders reviewed", "blundersReviewed"],
          ["Blunders discarded", "blundersDiscarded"],
          ["Puzzles created", "puzzlesCreated"],
          ["Puzzles assigned", "puzzlesAssigned"],
          ["Puzzles reviewed", "puzzlesReviewed"],
          ["Puzzles discarded", "puzzlesDiscarded"],
          ["Puzzles approved", "puzzlesApproved"],
          [
            "Actions",
            (item) => {
              return (
                <Button
                  onClick={() => assignToUser(item)}
                  variant="contained"
                  color="primary"
                  size='small'
                >
                  Assign
                </Button>
              );
            },
          ],
        ]}
      />
      <AssignModal
        open={open}
        onClose={() => {
          setRefresh(refresh + 1);
          setOpen(false);
        }}
        user={currentUser}
      />
    </>
  );
};

const AssignModal = (props) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const loadGroups = async () => {
      const results = await ApiService.get("categorization/summary/available");
      setGroups(results);
    };
    loadGroups();
  }, []);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const assignItems = async (itemType, userId, itemGroup, amount) => {
    try {
      const result = await ApiService.post("categorization/assign", {
        itemType,
        userId,
        itemGroup,
        amount,
      });
      return result;
    } catch (e) {
      closeSnackbar();
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  };

  if (!props.user) return null;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Assign items to review</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Assigning items to: {props.user.email}.<br />
          Please select the type (blunder, puzzle), group and number of items to
          assign
        </DialogContentText>
        <Formik
          initialValues={{
            userId: props.user.id,
            itemType: "blunders",
            itemGroup: "",
            amount: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.itemGroup || !values.itemGroup.length) {
              errors.itemGroup = "Please select the group";
            }
            if (!values.amount) {
              errors.amount = "Please enter the amount";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let result = await assignItems(
              values.itemType,
              values.userId,
              values.itemGroup,
              values.amount
            );
            setSubmitting(false);

            if (result) {
              enqueueSnackbar((values.itemType == 'blunder' ? 'Blunders' : 'Puzzles') + " assigned successfuly!", {
                variant: "success",
              });
              props.onClose();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <Field
                    name="itemType"
                    component={Autocomplete}
                    options={["blunders", "puzzles"]}
                    getOptionLabel={(option: any) => option || ""}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Type"
                        error={touched["itemType"] && !!errors["itemType"]}
                        helperText={errors["itemType"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="itemGroup"
                    component={Autocomplete}
                    options={groups}
                    getOptionLabel={(option: any) => option || ""}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Group"
                        error={touched["itemGroup"] && !!errors["itemGroup"]}
                        helperText={errors["itemGroup"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={FormikTextField}
                    type="number"
                    label="Amount"
                    name="amount"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid style={{ textAlign: "right", marginTop: 20 }}>
                <Button
                  onClick={props.onClose}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </Button>
              </Grid>
              {isSubmitting && <LinearProgress style={{ margin: "10px 0" }} />}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default BrowseUsers;
