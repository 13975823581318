import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  LinearProgress,
  ButtonGroup,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CrudTable } from "../../../ui/components/tables/CrudTable";
import { Autocomplete } from "formik-material-ui-lab";
import TagsFilter from "./filters/tags.filter";
import UsersFilter from "./filters/users.filter";
import StatusFilter from "./filters/status.filter";
import DifficultyFilter from "./filters/difficulty.filter";
import ApiService from "../../../domain/services/ApiService";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import { Select, TextField as FormikTextField } from "formik-material-ui";
import _ from 'lodash';
import PgnModal from "../../../ui/components/modals/pgn.modal";
import { RemoteIdFilter } from "./filters/remote-id.filter";


function createData(id, status, tag, difficulty, reviewer, modified) {
  return { id, status, tag, difficulty, reviewer, modified };
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const printStatus = (row) => {
  switch (row.status) {
    case "pending_review":
      return "Waiting for review";
    default:
      return _.startCase(row.status);
  }
};

const BrowsePuzzles = (props) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(1);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [currentPgn, setCurrentPgn] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showAssignModal = selected => {
    setOpen(true);
    setSelected(selected);
  }

  const approve = async (selected) => {
    setSelected(selected);
    if(window.confirm('Are you sure you would like to approve ' + selected.length + ' puzzles?')) {
      await approveItems(selected);
      setRefresh(refresh + 1);
      setSelected([]);
      enqueueSnackbar("Puzzles approved successfuly!", {
        variant: "success",
      });
    }
  }


  const approveItems = async (selectedPuzzles) => {
    try {
      const result = await ApiService.post("puzzle/approve/multiple", {
        puzzleIds: selectedPuzzles.map(x => x.id)
      });
      return result;
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CrudTable
        multiselect
        multiselectActions={[
          {
            label: "Assign for review",
            onSelect: (selected) => {
              showAssignModal(selected);
            },
          },
          {
            label: "Approve",
            onSelect: (selected) => {
              approve(selected);
            },
          },
        ]}
        refresh={refresh}
        resource={"puzzle"}
        params={{
          join: {
            primaryTag: {
              eager: true,
            },
          },
        }}
        additionalFilterFields={[
          {
            id: "tags",
            render: ({ onFilterChange }) => {
              return (
                <TagsFilter
                  onChange={(value) =>
                    onFilterChange(
                      "tags",
                      value ? value.map((x) => x.id) : null
                    )
                  }
                />
              );
            },
          },
          {
            id: "users",
            render: ({ onFilterChange }) => {
              return (
                <UsersFilter
                  onChange={(value) =>
                    onFilterChange(
                      "users",
                      value ? value.map((x) => x.id) : null
                    )
                  }
                />
              );
            },
          },
          {
            id: "status",
            render: ({ onFilterChange }) => {
              return (
                <StatusFilter
                  onChange={(value) =>
                    onFilterChange("status", value ? value.id : null)
                  }
                />
              );
            },
          },
          {
            id: "difficulty",
            render: ({ onFilterChange }) => {
              return (
                <DifficultyFilter
                  onChange={(value) =>
                    onFilterChange("difficulty", value ? value.id : null)
                  }
                />
              );
            },
          },
          {
            id: "remoteId",
            render: ({ onFilterChange }) => {
              return (
                <RemoteIdFilter
                  onChange={(value) =>
                    onFilterChange("ksqReferenceId", value ? value : null)
                  }
                />
              );
            },
          }
        ]}
        columns={[
          // ["ID", "id"],
          ["Puzzle ID", "id"],
          ["Status", (status) => printStatus(status)],
          ["Tag", "primaryTag.title"],
          [
            "Secondary Tags",
            (row) =>
              row.secondaryTags && row.secondaryTags.length
                ? row.secondaryTags.map((x) => x.tag ? x.tag.title : 'none').join(", ")
                : "-",
          ],
          ["Remote ID", "ksqReferenceId"],
          ["Difficulty", "rating"],
          ["Reviewer", "createdBy.name"],
          ["Last Modified", "updatedAt"],
          [
            "Actions",
            (item) => {
              return (
                <>
                <ButtonGroup>
                  <Button
                    onClick={() => props.history.push("/puzzle/" + item.hash)}
                    variant="contained"
                    color="primary"
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setCurrentPgn(item.pgn)}
                    variant="contained"
                    color="primary"
                  >
                    PGN
                  </Button>
                </ButtonGroup>
                </>
              );
            },
          ],
        ]}
      />
      <AssignModal
        open={open}
        selected={selected}
        onClose={() => {
          setRefresh(refresh + 1);
          setOpen(false);
        }}
      />
      <PgnModal
        open={!!currentPgn}
        pgn={currentPgn}
        onClose={() => {
          setCurrentPgn(null);
        }}
      />
    </>
  );
};

const AssignModal = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      const users = await ApiService.get("users");
      setUsers(users.data);
    }

    loadUsers();
  }, [])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const assignItems = async (selectedPuzzles, user) => {
    try {
      const result = await ApiService.post("categorization/assign/puzzles", {
        userId: user.id,
        puzzleIds: selectedPuzzles.map(x => x.id)
      });
      return result;
    } catch (e) {
      closeSnackbar();
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  };

  // if (!props.user) return null;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Assign selected puzzles</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select a user to assign puzzles to:
        </DialogContentText>
        <Formik
          initialValues={{
            puzzles: props.selected,
            user: null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.user) {
              errors.user = "Please select the user";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let result = await assignItems(
              props.selected,
              values.user
            );
            setSubmitting(false);

            if (result) {
              enqueueSnackbar("Puzzles assigned successfuly!", {
                variant: "success",
              });
              props.onClose();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <Field
                    name="user"
                    component={Autocomplete}
                    options={users}
                    getOptionLabel={(option) => option.email}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="User"
                        error={touched["user"] && !!errors["user"]}
                        helperText={errors["user"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid style={{ textAlign: "right", marginTop: 20 }}>
                <Button
                  onClick={props.onClose}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </Button>
              </Grid>
              {isSubmitting && <LinearProgress style={{ margin: "10px 0" }} />}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default BrowsePuzzles;
