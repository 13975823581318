import React, { useState } from "react";
import Chessground from "react-chessground";
import "react-chessground/dist/styles/chessground.css";
import { Dialog } from "@material-ui/core";
import queen from "./../../../assets/images/wQ.svg";
import rook from "./../../../assets/images/wR.svg";
import bishop from "./../../../assets/images/wB.svg";
import knight from "./../../../assets/images/wN.svg";

const Board = (props) => {
  const [k, setK] = useState(0);
  return (
    <div className="cg-wrap-wrapper">
      <Chessground
        key={k}
        width={584}
        height={584}
        turnColor={props.turnColor}
        movable={props.movable}
        lastMove={props.lastMove}
        fen={props.fen}
        onMove={(from, to) => {
          if(!props.isLastMove) {
            if(!window.confirm('This move will clear all subsequent moves in the list. Are you sure?')) {
              // Rerender the board
              setK(k + 1);
              return false;
            }
          }
          props.onMove(from, to);
        }}
        viewOnly={false}
        highlight={{
          lastMove: true,
        }}
        orientation={props.orientation}
        ref={(el) => {}}
      />
      <Dialog open={props.selectPromotion} footer={null} closable={false}>
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <span
            role="presentation"
            onClick={() => props.onPromotionSelected("q")}
          >
            <img src={queen} alt="" style={{ width: 50 }} />
          </span>
          <span
            role="presentation"
            onClick={() => props.onPromotionSelected("r")}
          >
            <img src={rook} alt="" style={{ width: 50 }} />
          </span>
          <span
            role="presentation"
            onClick={() => props.onPromotionSelected("b")}
          >
            <img src={bishop} alt="" style={{ width: 50 }} />
          </span>
          <span
            role="presentation"
            onClick={() => props.onPromotionSelected("n")}
          >
            <img src={knight} alt="" style={{ width: 50 }} />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default Board;
