import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const StatusFilter = props => {
  const [statuses, setStatuses] = useState(
    props.options
      ? props.options
      : [
          {
            id: "pending_review",
            label: "Waiting for review",
          },
          {
            id: "assigned",
            label: "Assigned",
          },
          {
            id: "approved",
            label: "Approved",
          },
          {
            id: "rejected",
            label: "Rejected",
          },
        ]
  );

  return (
    <Autocomplete
      options={statuses}
      getOptionLabel={(option) => option.label}
      onChange={(event, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Status"
          variant="outlined"
          size="small"
          style={{minWidth: 200}}
        />
      )}
    />
  )
}

export default StatusFilter;
