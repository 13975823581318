import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";

const getMoves = (data, scoreMultiplier = 1) => {
  let depths = Object.keys(data);
  let lastDepth = Math.max.apply(null, depths);
  let moves = [];

  for (let i = 1; i <= 3; i++) {
    let currentDepth = lastDepth;
    while (
      currentDepth > 0 &&
      (!data[currentDepth] || !data[currentDepth][i])
    ) {
      currentDepth--;
    }
    if (data[currentDepth] && data[currentDepth][i]) {
      moves.push({ ...data[currentDepth][i] });
    }
  }

  moves = moves.map((x) => {
    x.score = x.score * scoreMultiplier;
    return x;
  });

  // Order moves by score
  // moves.sort((a, b) => (a.score < b.score ? 1 : -1));

  return moves;
};

const renderBar = (percentage, score) => {
  if(percentage == null) return null;

  return (
    <Grid
        style={{
          height: "100%",
          width: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
          <div
            style={{
              width: 5,
              height: "100%",
              backgroundColor: "#040404",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: Math.round(percentage) + "%",
                width: "100%",
                borderRadius: 5,
                transition: "height 0.15 ease-out",
                maxHeight: "100%",
              }}
            ></div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>{score}</div>
      </Grid>
  )
}

export const AnalysisPanel = React.memo((props) => {
  const [analysisData, setAnalysisData] = useState(null);

  useEffect(() => {
    setAnalysisData(null);
    props.analysis.onAnalysisData(
      (data) => {
        if (data && data.data) {
          setAnalysisData(data);
        }
      }
    );
  }, [props.analysis]);

  if (!analysisData || !analysisData.data) return null;

  // Calculate score multiplier
  let scoreMultiplier = analysisData.multiplier;

  let moves = getMoves(analysisData.data, scoreMultiplier);

  if(props.analysis.getStartTurn() === 'w') {
    moves = moves.map(x => {
      x.score = x.score *  -1;
      return x;
    })
  }

  let score = moves.length && moves[0].score ? moves[0].score : null;

  let percentage = score ? (50 + (score * 50) / 8) : 100;

  // :)
  let checkmateMessage = _.get(analysisData, 'data.0.0.mateIn', null) === 0 ? 'Checkmate!' : null;

  return (
    <Grid
      display="flex"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "20px 10px",
        boxSizing: "border-box",
      }}
    >
      {renderBar(percentage, score)}
      <Grid style={{ flexGrow: 1 }}>
        {checkmateMessage ? checkmateMessage : null}
        {moves.map((move, index) => (
          <div key={"move" + index}>
            <div style={{ padding: "20px 0" }}>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "white",
                      padding: 3,
                      color: "#000000",
                      borderRadius: 4,
                    }}
                  >
                    {move.mateIn ? ("Mate in " + Math.abs(move.mateIn)) : move.score}
                  </span>{" "}
                  {/* <span style={getStyleForIndex(index)}>{move.score}</span>{" "} */}
                  {/* {getTextForIndex(index)} */}
                </div>
                {/* <div style={{ fontSize: 12 }}>{move.score}</div> */}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {move.bestLine &&
                  props.analysis.toAlgebricNotation(
                    move.bestLine.split(" ").slice(0, 6).join(" ")
                  )}
              </div>
            </div>
            {index < moves.length - 1 ? (
              <div
                style={{
                  height: 1,
                  borderRadius: 2.5,
                  width: "100%",
                  margin: "10px 0",
                  background: "#151515",
                  boxShadow:
                    "0px 1px 1px rgba(255, 255, 255, 0.1), inset 0px 1px 3px rgba(0, 0, 0, 0.5)",
                }}
              ></div>
            ) : null}
          </div>
        ))}
      </Grid>
    </Grid>
    // <div>{lastDepth}</div>
  );
});

export default AnalysisPanel;
