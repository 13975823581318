import ApiService from "../../../domain/services/ApiService";

export class ReviewService {
  static async dismissItem(type, hash, reason = null) {
    const result = await ApiService.post(type + "/" + hash, {
      reason
    });

    return result;
  }

  static async approveItem(
    type,
    hash,
    primaryTag,
    secondaryTags,
    difficulty,
    pgn,
    pv,
    fen,
    lastMove
  ) {

    switch(type) {
      case 'blunder':
        return await this.approveBlunder(hash, primaryTag, secondaryTags, difficulty, pgn, pv);
      case 'puzzle':
        return await this.approvePuzzle(hash, primaryTag, secondaryTags, difficulty, pgn, pv, fen, lastMove);
    }

  }

  static async approveBlunder(hash, primaryTag, secondaryTags, difficulty, pgn, pv) {
    return await ApiService.post("blunder/" + hash + "/puzzle", {
      primaryTag: primaryTag.id,
      secondaryTags: secondaryTags.map(x => x.id),
      pgn,
      rating: difficulty
    });
  }

  static async approvePuzzle(hash, primaryTag, secondaryTags, difficulty, pgn, pv, fen, lastMove) {
    return await ApiService.post("puzzle/" + hash + "/approve", {
      primaryTag: primaryTag.id,
      secondaryTags: secondaryTags.map(x => x.id),
      pgn,
      rating: difficulty,
      fen,
      lastMove
    });
  }

  static async getNext(type = 'blunder') {
    try {
      const next = await ApiService.get(type + "/next");

      if (next) {
        return '/' + type + "/review/" + next.hash;
      }

      return "/";
    } catch(e) {
      throw e;
    }
  }

  static getAllDoneUrl(type = 'blunder') {
    return '/' + type + '/all-done';
  }

  static async getPuzzleForAdmin(hash) {
    const response = await ApiService.get('puzzle/admin/' + hash);
    if(response) {
      return response.hash;
    }
  }

  static async getBlunderForAdmin(id) {
    const response = await ApiService.get('blunder/admin/' + id);
    if(response) {
      return response.hash;
    }
  }
}
