import React from "react";
import { Button } from "@material-ui/core";

export const BlunderAllDonePage = (props) => {
  return (
    <div
      style={{
        margin: "40px auto",
        fontSize: 40,
        textAlign: "center",
        color: "#ffffff",
      }}
    >
      Well done!
      <p>No more blunders to review</p>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.history.replace("/puzzle/review")}
      >
        Review Puzzles
      </Button>
    </div>
  );
};

export default BlunderAllDonePage;
