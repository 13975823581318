export const SET_HEADER_ACTIONS_LEFT = 'SET_HEADER_ACTIONS_LEFT';
export const SET_HEADER_ACTIONS_RIGHT = 'SET_HEADER_ACTIONS_RIGHT';

export const setHeaderActionsLeftAction = actions => ({
  type: SET_HEADER_ACTIONS_LEFT,
  payload: actions
});

export const setHeaderActionsRightAction = actions => ({
  type: SET_HEADER_ACTIONS_RIGHT,
  payload: actions
});
