import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ApiService from "../../../../domain/services/ApiService";

export const TagsFilter = (props) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const loadTags = async () => {
      const tags = await ApiService.get("tags");

      const finalTags = [];
      for (let i = 0; i < tags.length; i++) {
        const currentTag = tags[i];
        finalTags.push(currentTag);
        currentTag.children &&
          currentTag.children.length &&
          currentTag.children.map((child) => {
            finalTags.push({
              ...child,
              child: true,
            });
            return null;
          });
      }
      setTags(finalTags);
    };

    loadTags();
  }, []);

  return (
    <Autocomplete
      multiple
      options={tags}
      getOptionLabel={(option) => option.title}
      onChange={(event, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          variant="outlined"
          size="small"
          style={{ minWidth: 200 }}
        />
      )}
      renderOption={(option) => (
        <Typography noWrap style={{ marginLeft: option.child ? 20 : 0 }}>
          {option.title}
        </Typography>
      )}
    />
  );
};

export default TagsFilter;
