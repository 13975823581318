import React, { useState } from "react";
import {
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CrudTable } from "../../../ui/components/tables/CrudTable";
import TagsFilter from "./filters/tags.filter";
import UsersFilter from "./filters/users.filter";
import StatusFilter from "./filters/status.filter";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const printStatus = (row) => {
  switch (row.status) {
    case "pending_review":
      return "Waiting for review";
    case null:
      return "Unassigned";
    default:
      return row.status;
  }
};

const printReviewers = (item) => {
  return (
    <>
      {item.assignedBlunders.map((x) => {
        if (x.status) {
          return (
            <div>
              {x.user.name} ({x.status})
            </div>
          );
        }
      })}
    </>
  );
};

const BrowseBlunders = (props) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(1);
  return (
    <CrudTable
      refresh={refresh}
      resource={"blunders"}
      params={{}}
      additionalFilterFields={[
        {
          id: "users",
          render: ({ onFilterChange }) => {
            return (
              <UsersFilter
                onChange={(value) =>
                  onFilterChange(
                    "users",
                    value ? value.map((x) => x.id) : null
                  )
                }
              />
            );
          },
        },
        {
          id: "status",
          render: ({ onFilterChange }) => {
            return (
              <StatusFilter
              options={[
                {
                  id: 'assigned',
                  label: 'Assigned'
                },
                {
                  id: 'skipped',
                  label: 'Skipped'
                },
                {
                  id: 'reviewed',
                  label: 'Reviewed'
                },
              ]}
                onChange={(value) =>
                  onFilterChange("status", value ? value.id : null)
                }
              />
            );
          },
        },
      ]}
      columns={[
        ["Blunder ID", "id", { style: { width: 100 } }],
        ["FEN before", "fenbefore"],
        ["Reviewer", (item) => printReviewers(item)],
        ["Status", (status) => printStatus(status)],
        ["Blunder Group", "blunderGroup"],
        [
          "Actions",
          (item) => {
            return (
              <Button
                onClick={() => props.history.push("/blunder/" + item.id)}
                variant="contained"
                color="primary"
              >
                View
              </Button>
            );
          },
        ],
      ]}
    />
  );
};

export default BrowseBlunders;
