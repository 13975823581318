import { combineReducers } from 'redux';
import { SET_HEADER_ACTIONS_LEFT, SET_HEADER_ACTIONS_RIGHT } from './ui.actions';

function left(state = [], action) {
  switch (action.type) {
    case SET_HEADER_ACTIONS_LEFT:
      return [...action.payload]
    default:
      return [...state]
  }
}

function right(state = [], action) {
  switch (action.type) {
    case SET_HEADER_ACTIONS_RIGHT:
      return [...action.payload]
    default:
      return [...state]
  }
}

export default combineReducers({
  header: combineReducers({
    left: left,
    right: right
  })
});
