import Chess from "chess.js";
import { Engine } from "./engine";
import _ from "lodash";

export class Analysis {
  constructor(options) {
    this.options = options;
    this.algebricCaches = {};
    this.chess = new Chess();
    this.engine = new Engine({
      onEvaluationCB: (depth, multipv, score, bestMove, bestLine, mateIn) => {
        this.onEvaluation(depth, multipv, score, bestMove, bestLine, mateIn);
      },
    });
  }

  onEvaluation(depth, multipv, score, bestMove, bestLine, mateIn) {
    let analysisData = this.analysisData;
    if (!analysisData) analysisData = {};

    analysisData = { ...analysisData };

    if (!analysisData[depth]) analysisData[depth] = {};

    analysisData[depth][multipv] = {
      score,
      bestMove,
      bestLine,
      mateIn,
    };

    this.updateAnalysisData(analysisData);
  }

  updateAnalysisData(data = null) {
    this.analysisData = data;

    if (data) {
      this.multiplier =
        (this.getStartTurn() === "b" ? "black" : "white") != this.turnColor()
          ? 1
          : -1;
    }

    const callbackData = {
      data: this.analysisData,
      multiplier: this.multiplier,
    };

    if (this.options.onAnalysisDataChange) {
      this.options.onAnalysisDataChange(callbackData);
    }

    if (this.analysisCallback) {
      this.analysisCallback(callbackData);
    }
  }

  updateFen(fen) {
    this.fen = fen;
    if (this.options.onFenChange) {
      this.options.onFenChange(this.fen);
    }
  }

  updateSelectedMove(index) {
    this.selectedMove = index;
    if (this.options.onSelectedMoveChange) {
      this.options.onSelectedMoveChange(this.selectedMove);
    }
  }

  updateMoves(moves = null) {
    if (!moves) {
      moves = this.chess.history({ verbose: true });
    }

    this.moves = moves;
    if (this.options.onMovesChange) {
      this.options.onMovesChange(this.moves);
    }
  }

  clearCache() {
    this.algebricCaches = {};
  }

  loadPgn(pgn, fenBefore = null) {
    this.clearCache();
    // const newChess = new Chess();
    let loaded = this.chess.load_pgn(pgn);

    if (!loaded) {
      alert("Invalid PGN!!!");
      return;
    }

    this.startHeaders = this.chess.header();
    this.startFen =
      this.startHeaders["FEN"] ||
      "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
    this.startTurn = this.chess.turn();
    this.updateFen(this.startFen);

    const moves = this.chess.history({ verbose: true });

    this.moves = moves;
    this.updateMoves(moves);

    let blunderMove = moves[0];
    setTimeout(() => {
      this.playToMove(0);
      setTimeout(() => {
        this.options.onLastMoveChanged([blunderMove.from, blunderMove.to]);
        setTimeout(() => {
          this.options.onLastMoveChanged(null);
        }, 2000);
      }, 200);
    }, 2000);

    this.engine.newGame();

    // this.chess.reset();
    // for (let i in moves) {
    //   this.chess.move(moves[i]);
    //   let fen = this.chess.fen();
    //   if (fen == this.options.fenBefore) {
    //     let blunderMove = moves[parseInt(i) + 1];
    //     this.updateFen(fen);

    //     setTimeout(() => {
    //       this.playToMove(parseInt(i) + 1);
    //       setTimeout(() => {
    //         this.options.onLastMoveChanged([blunderMove.from, blunderMove.to]);
    //         setTimeout(() => {
    //           this.options.onLastMoveChanged(null);
    //         }, 2000);
    //       }, 200);
    //     }, 1000);
    //     break;
    //   }
    // }

    // if(!this.options.fenBefore) {
    //   this.playToMove(moves.length - 1);
    // }
  }

  playToMove(index, silent = false) {
    this.chess.load(this.startFen);
    for (let i = 0; i <= index; i++) {
      this.chess.move(this.moves[i]);
    }

    if (!silent) {
      this.updateFen(this.chess.fen());
      this.options.onLastMoveChanged(null);
      this.updateSelectedMove(index);
      this.updateAnalysisData(null);
      this.engine.stopEngine();
      this.runAnalysis();
      this.selectPromotion = false;
    }
  }

  deleteAfter(move) {
    this.updateMoves([...this.moves].slice(0, move.index + 1));
    this.playToMove(move.index);
  }

  turnColor() {
    return this.chess.turn() === "w" ? "white" : "black";
  }

  calcMovable() {
    const dests = new Map([]);
    this.chess.SQUARES.forEach((s) => {
      const ms = this.chess.moves({ square: s, verbose: true });
      if (ms.length)
        dests.set(
          s,
          ms.map((m) => m.to)
        );
    });
    return {
      free: false,
      dests,
      color: this.turnColor(),
      // color: null,
      showDests: true,
    };
  }

  onMove(from, to) {
    const moves = this.chess.moves({ verbose: true });
    for (let i = 0, len = moves.length; i < len; i++) {
      /* eslint-disable-line */
      if (moves[i].flags.indexOf("p") !== -1 && moves[i].from === from) {
        this.pendingMove = [from, to];
        this.setSelectPromotion(true);
        return;
      }
    }

    this.move(from, to, "x");
  }

  move(from, to, promotion) {
    if (this.chess.move({ from, to, promotion })) {
      this.updateFen(this.chess.fen());
      this.options.onLastMoveChanged([from, to]);
      this.updateMoves();
      this.playToMove(this.moves.length - 1);
    } else {
      console.log("Failed move: ", this.chess.fen(), from, to, promotion);
    }
  }

  onAnalysisData(callback) {
    this.analysisCallback = callback;
  }

  runAnalysis = _.debounce(() => {
    this.updateAnalysisData(null);
    this.engine.startCalculatingBestMove(this.chess.fen());
  }, 1000);

  getPgn() {
    return this.chess.pgn();
  }

  getStartTurn() {
    return this.startTurn;
  }

  getOrientation() {
    return this.startTurn === "w" ? "black" : "white";
  }

  getFinalPgn() {
    this.playToMove(this.moves.length, true);

    // Append headers
    delete this.chess.header()["FEN"];
    delete this.chess.header()["SetUp"];
    Object.keys(this.startHeaders).forEach((key) => {
      this.chess.header(key, this.startHeaders[key]);
    });
    this.chess.header("FEN", this.startFen);
    this.chess.header("SetUp", "1");

    return this.chess.pgn();
  }

  getPV() {
    if (!this.analysisData) return null;

    const maxDepth = Math.max.apply(null, Object.keys(this.analysisData));

    // TODO: Validate if analysis is done
    if (this.analysisData[maxDepth][1]) {
      return this.analysisData[maxDepth][1].bestLine;
    }
  }

  getFen() {
    this.playToMove(this.moves.length, true);
    return this.chess.fen();
  }

  getLastMove() {
    return this.moves[this.moves.length - 1].san;
  }

  toAlgebricNotation(bestLine) {
    const cacheKey = this.fen + bestLine;

    // if(this.algebricCaches[cacheKey]) return this.algebricCaches[cacheKey];

    const chess = new Chess(this.fen);
    const moves = bestLine.split(" ");
    const sanMoves = [];
    for (let i = 0; i < moves.length; i++) {
      const parsedMove = chess.move(moves[i], { sloppy: true });
      if (parsedMove) {
        sanMoves.push(parsedMove.san);
      }
    }

    this.algebricCaches[cacheKey] = sanMoves.join();

    return this.algebricCaches[cacheKey];
  }

  setSelectPromotion(selectPromotion) {
    this.selectPromotion = selectPromotion;
    if (this.options.onSelectPromotionChange) {
      this.options.onSelectPromotionChange(this.selectPromotion);
    }
  }

  shouldSelectPromotion() {
    return this.selectPromotion;
  }

  onPromotionSelected(e) {
    if (!this.pendingMove) return false;
    this.move(this.pendingMove[0], this.pendingMove[1], e);
    this.setSelectPromotion(false);
  }
}
