import React, { useEffect } from "react";
import {
  Container,
  MuiThemeProvider,
  makeStyles,
  Card,
  Button,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { userLogin } from "react-admin";
import classnames from "classnames";
import AuthService from "../services/AuthService";
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#151515",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "transparent",
    color: "white",
  },
  logo: {
    width: 50,
    height: "auto",
    marginBottom: 20,
  },
}));

const LoginPage = (props) => {

  const signInClick = (e) => {
    // TODO: Implement
    props.userLogin(props.user ? props.user : { username: "test" });
  };

  const classes = useStyles(props);
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if(props.user) {
      setTimeout(() => {
        //props.userLogin(props.user);
      }, 200)
    }
  });

  return (
    <div className={classnames(classes.main)}>
      <div className={classes.card}>
        <img
          alt="logo"
          src={require("../../../assets/logo.svg")}
          className={classes.logo}
        />
        <h2>Puzzles Categorizer</h2>
        <Button
          onClick={() => signInClick()}
          style={{ backgroundColor: "white" }}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps, { userLogin })(LoginPage);
