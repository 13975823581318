import React from "react";
import { Route, Redirect } from "react-router-dom";
import BrowsePage from "../modules/browse/pages/BrowsePage";
import TestBoard from "../modules/dashboard/components/TestBoard";
import { routes as blunderRoutes } from '../modules/blunders';
import { routes as puzzleRoutes } from '../modules/puzzles';


export const buildRoutes = user => {
  if(user.role == 'admin') {
    return [
      <Route exact path="/" component={BrowsePage} />,
      ...blunderRoutes,
      ...puzzleRoutes
    ];
  } else {
    return [
      <Route exact path="/" component={() => <Redirect to='/blunder/review'/>} />,
      ...blunderRoutes,
      ...puzzleRoutes
    ]
  }
}


export default [
  <Route exact path="/" component={BrowsePage} />,
  ...blunderRoutes,
  ...puzzleRoutes
];
