import React from "react";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import logo from "./logo.svg";
import "./App.css";
import restProvider from "ra-data-simple-rest";
import createAdminStore from "./domain/store/createAdminStore";
import PuzzlesAdmin from "./PuzzlesAdmin";
import authProvider from "./modules/auth/auth.provider";

const DEV_DOMAIN = 'puzzles.dev.keysquare.io';
const LIVE_DOMAIN = 'puzzles.kasparovchess.com';

export default class App extends React.Component {

  constructor() {
    super();
    if(window.location.host === DEV_DOMAIN) {
      window.location = 'https://' + LIVE_DOMAIN;
    }
  }

  render() {
    if(window.location.host === DEV_DOMAIN) {
      return <div>Please go to <a href={'https://' + LIVE_DOMAIN}>{LIVE_DOMAIN}</a></div>;
    }

    const dataProvider = restProvider(process.env.REACT_APP_API_ENDPOINT);
    const history = createHashHistory();

    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <PuzzlesAdmin
          // authProvider={authProvider}
          dataProvider={dataProvider}
          history={history}
        />
      </Provider>
    );
  }
}
