import { userLogin } from 'react-admin';
import ApiService from '../../../domain/services/ApiService';

export default class AuthService {

  static triggerLogin(user) {
    // This one creates redirection issues
    // userLogin(user);
  }

  /**
   * Pull user info
   */
  static async pullUserInfo() {
    let response = await ApiService.get(
      'auth/user'
    );

    return response;
  }

  static async logout() {
    try {
      const response = await ApiService.get('auth/logout');
      if(response && response.redirect) {
        window.location = response.redirect;
        return;
      }
    } catch(e) {}
  }
}
