import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ApiService from '../../../../domain/services/ApiService';


export const UsersFilter = props => {
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const loadUsers = async () => {
      const users = await ApiService.get("users");
      setUsers(users.data);
    }

    loadUsers();
  }, [])


  return (
    <Autocomplete
      multiple
      options={users}
      getOptionLabel={(option) => option.name ? option.name : option.email}
      onChange={(event, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Users"
          variant="outlined"
          size="small"
          style={{minWidth: 200}}
        />
      )}
    />
  )
}

export default UsersFilter;
