import React, { useEffect } from "react";
import { connect } from "react-redux";
import AppTabs from "../../../ui/components/tabs/AppTabs";
import BrowsePuzzles from "../components/BrowsePuzzles";
import BrowseUsers from "../components/BrowseUsers";
import { setHeaderActionsLeftAction, setHeaderActionsRightAction } from "../../../ui/store/ui.actions";
import BrowseBlunders from "../components/BrowseBlunders";

export const BrowsePage = (props) => {

  useEffect(() => {
    props.setRight([
      {
        title: 'Blunder review',
        to: '/blunder/review'
      },
      {
        title: 'Puzzle review',
        to: '/puzzle/review'
      }
    ])
  }, []);


  return (
    <div>
      <AppTabs
        tabs={[
          {
            tab: 'users',
            label: 'Users',
            component: <BrowseUsers/>
          },
          {
            tab: 'puzzles',
            label: 'Puzzles',
            component: <BrowsePuzzles history={props.history}/>
          },
          {
            tab: 'blunders',
            label: 'Blunders',
            component: <BrowseBlunders history={props.history}/>
          }

        ]}
      />
    </div>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    setLeft: actions => dispatch(setHeaderActionsLeftAction(actions)),
    setRight: actions => dispatch(setHeaderActionsRightAction(actions)),
  }
}
export default connect(null, mapDispatchToProps)(BrowsePage);
