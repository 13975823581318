import React from "react";
import { Button } from "@material-ui/core";

export const PuzzleAllDonePage = (props) => {
  return (
    <div
      style={{
        margin: "40px auto",
        fontSize: 40,
        textAlign: "center",
        color: "#ffffff",
      }}
    >
      Well done!
      <p>No more puzzles to review</p>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.history.replace("/blunder/review")}
      >
        Review Blunders
      </Button>
    </div>
  );
};

export default PuzzleAllDonePage;
