import React from "react";

export const CircleButton = (props) => {
  return (
    <button
      style={{
        cursor: "pointer",
        border: "none",
        width: 44,
        height: 44,
        background: "#2F2F2F",
        boxShadow:
          "inset -3px -3px 7px rgba(128, 128, 128, 0.15), inset 2px 3px 7px rgba(0, 0, 0, 0.57)",
        borderRadius: 32,
        overflow: 'hidden',
        outline: 'none'
      }}
      {...props}
    >
      <div
        style={{
          width: 32,
          height: 32,
          backgroundColor: "#ffffff",
          borderRadius: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 2,
        }}
      >
        {props.children}
      </div>
    </button>
  );
};

export const PreviousButton = (props) => {
  return (
    <CircleButton {...props}>
      <img src="/buttons/arrow-left.svg" />
    </CircleButton>
  );
};

export const PlayButton = (props) => {
  return (
    <CircleButton {...props}>
      <img src="/buttons/play.svg" />
    </CircleButton>
  );
};

export const PauseButton = (props) => {
  return (
    <CircleButton {...props}>
      <img src="/buttons/play.svg" />
    </CircleButton>
  );
};

export const NextButton = (props) => {
  return (
    <CircleButton {...props}>
      <img src="/buttons/arrow-right.svg" />
    </CircleButton>
  );
};
