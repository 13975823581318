import React, { useState, useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Board from "./components/board";
import MovesPanel from "./components/moves";
import AnalysisPanel from "./components/analysis";
import { Analysis } from "./services/analysis";
import { TagsPanel } from "./components/tags.panel";
import { ButtonsPanel } from "./components/buttons.panel";
import { ReviewService } from "./services/review.service";
import _ from "lodash";
import { useSnackbar } from "notistack";
import PgnModal from "../../ui/components/modals/pgn.modal";

let analysis = new Analysis();
const ReviewPanel = (props) => {
  let [fen, setFen] = useState("");
  const [hash, setHash] = useState(null);
  const [lastMove, setLastMove] = useState(null);
  const [moves, setMoves] = useState([]);
  const [selectedMove, setSelectedMove] = useState(null);
  const [selectPromotion, setSelectPromotion] = useState(false);
  const [currentPgn, setCurrentPgn] = useState(null);

  const [primaryTag, setPrimaryTag] = useState(props.primaryTag || null);
  const [secondaryTags, setSecondaryTags] = useState(props.secondaryTags || []);
  const [difficulty, setDifficulty] = useState(props.difficulty || null);

  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedMove(null);
    setFen(null);
    setLastMove(null);
    setMoves([]);
    setPrimaryTag(props.primaryTag || null);
    setSecondaryTags(props.secondaryTags || []);
    setDifficulty(props.difficulty || null);
    setHash(props.hash);
    setSelectPromotion(false);

    analysis = new Analysis({
      fenBefore: props.fenBefore,
      onMovesChange: (moves) => setMoves(moves),
      onSelectedMoveChange: (index) => setSelectedMove(index),
      onLastMoveChanged: (lastMove) => setLastMove(lastMove),
      onFenChange: (fen) => setFen(fen),
      onTurnChange: turn => console.log(turn),
      bestMoveCB: (props) => {},
      onAnalysisDataChange: (data) => {},
      onSelectPromotionChange: (selectPromotion) => setSelectPromotion(selectPromotion),
    });

    // Load the pgn
    analysis.loadPgn(props.pgn);
  }, [props.hash]);

  const playToMove = (index) => {
    analysis.playToMove(index);
  };

  const deleteAfter = (move) => {
    analysis.deleteAfter(move);
  };

  const dismissItem = async () => {
    if (props.onDismiss) {
      return props.onDismiss(props.type, props.hash);
    }

    const result = await ReviewService.dismissItem(props.type, props.hash);

    if (result) {
      props.history.push(await ReviewService.getNext(props.type));
    }
  };

  const acceptItem = async () => {
    // Validate primary tag and difficulty
    const currentErrors = { ...errors };

    if (!primaryTag) {
      currentErrors["primaryTag"] = "Please select the primary tag";
    }

    if (!difficulty) {
      currentErrors["difficulty"] = "Please select difficulty";
    }

    setErrors(currentErrors);

    if (!_.isEmpty(currentErrors)) {
      // TODO: Handle this on input level - on change clear error
      setTimeout(() => {
        setErrors({});
      }, 3000);
      return;
    }

    try {
      const result = await ReviewService.approveItem(
        props.type,
        props.hash,
        primaryTag,
        secondaryTags,
        difficulty,
        analysis.getFinalPgn(),
        analysis.getPV(),
        analysis.getFen(),
        analysis.getLastMove()
      );

      if (result) {
        enqueueSnackbar(
          props.type == "blunder"
            ? "Blunder sent to review!"
            : "Puzzle accepted",
          {
            variant: "success",
          }
        );
        setPrimaryTag(null);
        setSecondaryTags([]);
        setDifficulty(null);
        props.history.push(await ReviewService.getNext(props.type));
      }
    } catch (e) {
      if (e.statusCode == 404) {
        props.history.push(ReviewService.getAllDoneUrl(props.type));
      } else {
        enqueueSnackbar(e.message, {
          wariant: "error",
        });
      }

    }
  };

  return (
    <>
      <Box display="flex" style={{ width: "100%", height: 616 }}>
        <Box>
          <Board
            fen={fen}
            turnColor={analysis.turnColor()}
            startTurn={analysis.getStartTurn()}
            orientation={analysis.getOrientation()}
            movable={analysis.calcMovable()}
            onMove={(from, to) => analysis.onMove(from, to)}
            selectPromotion={selectPromotion}
            onPromotionSelected={e => analysis.onPromotionSelected(e)}
            lastMove={lastMove}
            isLastMove={selectedMove === moves.length - 1}
          />
        </Box>
        <Box style={{ flex: 1 }}>
          <Paper
            style={{
              marginLeft: 10,
              height: "100%",
              borderRadius: 6,
              backgroundColor: "#151515",
              boxShadow: "0px 14px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <MovesPanel
              moves={moves}
              selectedMove={selectedMove}
              onMoveClick={(index) => {
                if (index > moves.length - 1 || index < 0) return;
                setSelectedMove(index);
                playToMove(index);
              }}
              deleteAfter={(move) => {
                deleteAfter(move);
              }}
              startTurn={analysis.getStartTurn()}
              copyPgn={() => {
                setCurrentPgn(analysis.getPgn())
              }}
            />
          </Paper>
        </Box>
        <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box style={{ flex: 5 }}>
            <Paper
              style={{
                marginLeft: 10,
                height: "100%",
                borderRadius: 6,
                backgroundColor: "#151515",
                boxShadow: "0px 14px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <AnalysisPanel analysis={analysis} />
            </Paper>
          </Box>
          <Box style={{ flex: 3 }}>
            <Paper
              style={{
                marginLeft: 10,
                marginTop: 10,
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <TagsPanel
                hash={hash}
                errors={errors}
                primaryTagChanged={(tag) => setPrimaryTag(tag)}
                secondaryTagsChanged={(tag) => setSecondaryTags(tag)}
                difficultyChanged={(tag) => setDifficulty(tag)}
                primaryTag={primaryTag}
                secondaryTags={secondaryTags}
                difficulty={difficulty}
              />
              <ButtonsPanel
                type={props.type}
                onDismiss={() => dismissItem()}
                onAccept={() => acceptItem()}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
      <PgnModal
        open={!!currentPgn}
        pgn={currentPgn}
        onClose={() => {
          setCurrentPgn(null);
        }}
      />
    </>
  );
};

export default ReviewPanel;
