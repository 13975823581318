import React from "react";
import TestBoard from "./components/TestBoard";
import { Link } from "react-router-dom";
import { MenuList, MenuItem } from "@material-ui/core";

export class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <MenuList style={{ backgroundColor: "white", maxWidth: 200 }}>
          <MenuItem onClick={() => this.props.history.push("/browse")}>
            Browse
          </MenuItem>
          <MenuItem onClick={() => this.props.history.push("/board")}>
            Board
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}
