import { combineReducers } from 'redux';
import { STORE_USER } from './auth.actions';

function user(state = null, action) {
  switch (action.type) {
    case STORE_USER:
      return {...action.payload};
    default:
      return state
  }
}

export default combineReducers({
  user
});
