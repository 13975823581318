import React from "react";
import { Layout } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import { withStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  main: {
    backgroundColor: "#151515",
  },
});

const CustomLayout = (props) => {
  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Layout {...props} appBar={CustomAppBar}>
      <Container>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={2000}
          action={(key) => (
            <Button onClick={onClickDismiss(key)}>Close</Button>
          )}
        >
          <div style={{ marginTop: 30 }}>{props.children}</div>
        </SnackbarProvider>
      </Container>
    </Layout>
  );
};

export default withStyles(styles)(CustomLayout);
