import React from "react";
import { Button } from "@material-ui/core";

export const ButtonsPanel = (props) => {
  return (
    <div style={{ marginTop: 10, display: "flex" }}>
      <Button color="secondary" style={{ flex: 1 }} onClick={() => props.onDismiss()}>
        {props.type == 'blunder' ? 'Dismiss' : 'Dismiss'}
      </Button>
      <Button color="primary" variant="contained" style={{ flex: 1 }} onClick={() => props.onAccept()}>
        {props.type == 'blunder' ? 'Send for review' : 'Approve'}
      </Button>
    </div>
  );
};
