import React from "react";
import { PostList, PostEdit, PostCreate, PostIcon } from "./modules/posts";
import { Dashboard } from "./modules/dashboard";
import authProvider from "./modules/auth/auth.provider";
import CustomLayout from "./ui/components/layout/CustomLayout";
import theme from "./ui/theme";
import { buildRoutes } from "./routing";
import "./assets/styles/main.scss";
import LoginPage from "./modules/auth/pages/LoginPage";
import { Admin, Resource } from "react-admin";
import AuthService from "./modules/auth/services/AuthService";
import { connect } from "react-redux";
import { storeUserAction } from "./modules/auth/store/auth.actions";

class PuzzlesAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: true,
    };
  }

  componentDidMount() {
    this._pullUserInfo();
  }

  async _pullUserInfo() {
    let user = await AuthService.pullUserInfo();
    if (user) {
      this.props.storeUser(user);
    }
  }

  render() {
    if(!this.props.user) return null;

    const routes = buildRoutes(this.props.user);
    return (
      <Admin
          dataProvider={this.props.dataProvider}
          history={this.props.history}
          // authProvider={this.props.authProvider}
          theme={theme}
          dashboard={Dashboard}
          layout={CustomLayout}
          customRoutes={routes}
          // loginPage={LoginPage}
        >
          <Resource edit={PostEdit} create={PostCreate} icon={PostIcon} />
        </Admin>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (user) => dispatch(storeUserAction(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PuzzlesAdmin);
