import React from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const AppTab = withStyles((theme) => ({
  root: {
    color: "white",
    "&:hover": {
      color: "white",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "white",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function AppTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} py={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  indicator: {
    backgroundColor: "#7446FF",
  },
});

const AppTabs = (props) => {
  const classes = useStyles();

  const { tabs } = props;

  const [value, setValue] = React.useState(tabs[0].tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{ indicator: classes.indicator }}
      >
        {tabs.map((tab) => (
          <AppTab label={tab.label} value={tab.tab} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <AppTabPanel value={value} index={tab.tab}>
          {tab.component}
        </AppTabPanel>
      ))}
    </div>
  );
};

export default AppTabs;
