import React, { useState, useEffect } from "react";
import ApiService from "../../../domain/services/ApiService";
import ReviewPanel from "../../review/review.panel";
import { ReviewService } from "../../review/services/review.service";
import {
  setHeaderActionsLeftAction,
  setHeaderActionsRightAction,
} from "../../../ui/store/ui.actions";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import { TextField as FormikTextField } from "formik-material-ui";

const BlunderReviewPage = (props) => {
  const goToNext = async () => {
    try {
      props.history.replace(await ReviewService.getNext("puzzle"));
    } catch (e) {
      if (e.statusCode == 404) {
        props.history.replace(ReviewService.getAllDoneUrl("puzzle"));
      }
    }
  };

  const { hash } = props.match.params;

  let [blunderData, setBlunderData] = useState(null);
  let [dismissOpen, setDismissOpen] = useState(false);

  useEffect(() => {
    if (!hash) {
      goToNext();
    }
  }, []);

  useEffect(() => {
    if (!hash) return;

    const loadBunder = async (hash) => {
      try {
        const data = await ApiService.call("puzzle/assignment/" + hash);
        setBlunderData(data);

        const leftItems = [
          {
            title: "Puzzle No.",
            value: "#" + data.id,
          },
          {
            title: "Created",
            value: data.createdBy ? data.createdBy : "-",
          },
          {
            title: "Reviewed",
            value: data.reviewedBy ? data.reviewedBy : "-",
          },
        ];

        if(data.ksqReferenceId) {
          leftItems.push({
            title: "Remote Id",
            value: data.ksqReferenceId ? ("#" + data.ksqReferenceId) : "-",
          });
        }

        props.setLeft(leftItems);

        const rightActions = [
          {
            title: "Next",
            to: "",
          },
          {
            title: "Previous",
            to: "",
          },
          {
            title: "Blunder review",
            to: "/blunder/review",
          },
        ];

        if (props.user.role === "admin") {
          rightActions.push({
            title: "Admin",
            to: "/",
          });
        }

        props.setRight(rightActions);
      } catch (e) {
        if (e.statusCode == 404) {
          goToNext();
        }
      }
    };
    loadBunder(hash);

    return () => {
    };
  }, [hash]);

  let dismiss = () => {
    setDismissOpen(true);
  };

  if (!blunderData) return null;

  return (
    <>
      <ReviewPanel
        history={props.history}
        hash={blunderData.hash}
        type={blunderData.type}
        id={blunderData.id}
        pgn={blunderData.pgn}
        fenBefore={blunderData.fenBefore}
        lastMove={blunderData.blunderMove}
        primaryTag={blunderData.primaryTag}
        secondaryTags={blunderData.secondaryTags}
        difficulty={blunderData.rating}
        onDismiss={(type, hash) => dismiss(hash)}
      />
      <DismissPanel
        history={props.history}
        open={dismissOpen}
        onClose={() => setDismissOpen(false)}
        hash={blunderData.hash}
      />
    </>
  );
};

const DismissPanel = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Reject puzzle</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide a reason for rejection
        </DialogContentText>
        <Formik
          initialValues={{
            hash: props.hash,
            reason: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.reason || values.reason.trim().length == 0) {
              errors.reason = "Please fill the field";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let result = await ReviewService.dismissItem(
              "puzzle",
              props.hash,
              values.reason
            );
            setSubmitting(false);

            if (result) {
              enqueueSnackbar("Puzzle dismissed!", {
                variant: "success",
              });
              props.onClose();
              props.history.push(await ReviewService.getNext("puzzle"));
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <Field
                    component={FormikTextField}
                    type="text"
                    multiline
                    rows={5}
                    label="Reason"
                    name="reason"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid style={{ textAlign: "right", marginTop: 20 }}>
                <Button
                  onClick={props.onClose}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </Button>
              </Grid>
              {isSubmitting && <LinearProgress style={{ margin: "10px 0" }} />}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLeft: (actions) => dispatch(setHeaderActionsLeftAction(actions)),
    setRight: (actions) => dispatch(setHeaderActionsRightAction(actions)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BlunderReviewPage);
