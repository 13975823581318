import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import ApiService from "../../../domain/services/ApiService";

export const TagsPanel = (props) => {
  const [tags, setTags] = useState([]);
  const [secondaryTags, setSecondaryTags] = useState([]);
  const [diffs, setDiffs] = useState([]);
  const [additionalSelects, setAdditionalSelects] = useState([]);

  const loadSecondaryTags = async (parentId: any) => {
    let tags = [];
    if(!parentId) {
      tags = await ApiService.get("tags");
    } else {
      tags = await ApiService.get("tags", { parentId })
    }

    setSecondaryTags(tags);
  }

  const primaryTagChanged = (tagId) => {
    props.primaryTagChanged && props.primaryTagChanged(tagId);
    handleSecondaryTags(tagId);
    loadSecondaryTags(tagId ? tagId.id : null);
  };

  const secondaryTagsChanged = (tagIds) => {
    handleSecondaryTags(props.primaryTag, tagIds);
  };

  const subtagsChanged = (parentId, tagIds) => {
    let newTags = [...props.secondaryTags].filter(x => !x.parentId || x.parentId !== parentId);
    newTags = [...newTags, ...tagIds];
    props.secondaryTagsChanged && props.secondaryTagsChanged(newTags);
  }

  const handleSecondaryTags = (primaryTag = null, secondaryTags = null) => {
    if(secondaryTags === null) {
      secondaryTags = [...props.secondaryTags];
    }

    // Get parent secondary tags
    const parentSecondaryTags = secondaryTags.filter(x => !x.parentId);

    // Calculate allowed parentIds
    const allowedParentIds = parentSecondaryTags.map(x => x.id);
    if(primaryTag) {
      allowedParentIds.push(primaryTag.id);
    }

    // Get children tags
    let childrenTags = props.secondaryTags.filter(x => x.parentId);

    // Get allowed children tags
    let filteredChildrenTags = childrenTags.filter(x => allowedParentIds.find(y => y === x.parentId));

    // Join parent and children tags
    let finalTags = [...parentSecondaryTags, ...filteredChildrenTags];

    // Set secondary tags
    props.secondaryTagsChanged && props.secondaryTagsChanged(finalTags);
  }

  const listAdditionalSelects = () => {
    const additionalSelects = [];

    if(props.primaryTag && props.primaryTag.children.length) {
      additionalSelects.push({
        parentId: props.primaryTag.id,
        name: props.primaryTag.title,
        items: props.primaryTag.children
      });
    }

    props.secondaryTags.map(secondaryTag => {
      if(secondaryTag.children && secondaryTag.children.length) {
        additionalSelects.push({
          parentId: secondaryTag.id,
          name: secondaryTag.title,
          items: secondaryTag.children
        });
      }
    });

    setAdditionalSelects(additionalSelects);

  }

  const difficultyChanged = (difficulty) => {
    props.difficultyChanged && props.difficultyChanged(difficulty);
  };

  useEffect(() => {
    const loadTags = async (parentId = null) => {
      let result = await ApiService.get("tags");
      setTags(result);
      setSecondaryTags(result);

      if(props.primaryTag) {
        primaryTagChanged(result.find(x => x.id == props.primaryTag.id));
      } else {
        primaryTagChanged(null);
      }

      if(props.secondaryTags) {
        secondaryTagsChanged(result.filter(x => props.secondaryTags.filter(y => y.id == x.id).length > 0));
      } else {
        secondaryTagsChanged([]);
      }

    };

    const loadDiffs = async () => {
      setDiffs(await ApiService.get("categorization/summary/difficulties"));
    };

    loadTags();
    loadDiffs();
  }, [props.hash]);

  useEffect(() => {
    listAdditionalSelects();
  }, [props.primaryTag, props.secondaryTags, props.difficulty]);

  const secondaryTagsValue = props.secondaryTags
    .filter(x => props.secondaryTags.filter(y => y.id == x.parentId).length == 0)
    .filter(x => !props.primaryTag || props.primaryTag.id != x.parentId);

  return (
    <>
      <Autocomplete
        id="combo-box-primary-tag"
        options={tags}
        style={{ marginTop: 15 }}
        getOptionLabel={(option) => option.title}
        value={props.primaryTag}
        onChange={(event, value) => primaryTagChanged(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Primary tag"
            variant="outlined"
            error={!!props.errors["primaryTag"]}
            helperText={props.errors["primaryTag"]}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box-secondary-tags"
        options={secondaryTags.filter(x => !x.parentId && (!props.primaryTag || props.primaryTag.id != x.id))}
        style={{ marginTop: 15 }}
        getOptionLabel={(option) => option.title}
        value={secondaryTagsValue}
        onChange={(event, value) => secondaryTagsChanged(value)}
        renderInput={(params) => (
          <TextField {...params} label="Secondary tags" variant="outlined" />
        )}
      />
      {additionalSelects.map(additionalSelect => {
        return <Autocomplete
          multiple
          options={additionalSelect.items}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option.title}
          value={props.secondaryTags.filter(x => additionalSelect.items.find(y => y.id == x.id))}
          onChange={(event, value) => subtagsChanged(additionalSelect.parentId, value)}
          renderInput={(params) => (
            <TextField {...params} label={additionalSelect.name + ' tags'} variant="outlined" />
          )}
        />
      })}
      <Autocomplete
        id="combo-box-difficulty"
        options={diffs}
        style={{ marginTop: 15 }}
        getOptionLabel={(option) => "" + option}
        value={props.difficulty}
        onChange={(event, value) => difficultyChanged(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Difficulty"
            variant="outlined"
            error={!!props.errors["difficulty"]}
            helperText={props.errors["difficulty"]}
          />
        )}
      />
    </>
  );
};
