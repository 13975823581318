import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";

const myTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#1D1D1D",
    },
  },
  typography: {
    fontFamily: [
      'Maax',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
  ].join(','),
  },
  sidebar: {
    width: 0, // The default value is 240
    closedWidth: 0, // The default value is 55
  },

  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white", // Some CSS
      },

    },
    MuiInputLabel: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        // color: "orange",
        "&$focused": { // increase the specificity for the pseudo class
          color: "white"
        }
      }
    },
    MuiAutocomplete: {
      paper: {
        backgroundColor: '#000000'
      }
    }
  },
});

export default myTheme;
