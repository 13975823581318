import React, { useState, useEffect } from 'react';
import ApiService from '../../../domain/services/ApiService';
import ReviewPanel from '../../review/review.panel';
import { ReviewService } from '../../review/services/review.service';
import { setHeaderActionsLeftAction, setHeaderActionsRightAction } from '../../../ui/store/ui.actions';
import { connect } from 'react-redux';

const BlunderReviewPage = props => {

  const goToNext = async () => {
    try {
      props.history.replace(await ReviewService.getNext());
    } catch(e) {
      if(e.statusCode == 404) {
        props.history.replace(ReviewService.getAllDoneUrl("blunder"));
      }
    }
  }

  const { hash } = props.match.params;

  let [blunderData, setBlunderData] = useState(null);

  useEffect(() => {
    if(!hash) {
      goToNext();
    }

  }, [])

  useEffect(() => {
    if(!hash) return;

    const loadBunder = async hash => {
      try {

        const data = await ApiService.call('blunder/assignment/' + hash);
        setBlunderData(data);

        props.setLeft([
          {
            title: 'Blunders reviewed',
            value: data.blundersReviewed
          },
          {
            title: 'Blunders to review',
            value: data.blundersToReview
          },
          {
            title: 'Puzzles to review',
            value: data.puzzlesToReview
          }
        ]);

        const rightActions = [
          {
            title: 'Next',
            to: data.next ? ('/blunder/review/' + data.next) : null
          },
          {
            title: 'Previous',
            to: data.previous ? ('/blunder/review/' + data.previous) : null
          },
          {
            title: 'Puzzle review',
            to: '/puzzle/review'
          }
        ];

        if(props.user.role === 'admin') {
          rightActions.push({
            title: 'Admin',
            to: '/'
          })
        }

        props.setRight(rightActions)
      } catch(e) {
        if(e.statusCode == 404) {
          goToNext()
        }
      }
    }

    loadBunder(hash);

    return () => {
      // props.setLeft([]);
      // props.setRight([]);
    }
  }, [hash]);

  if(!blunderData) return null;

  return <ReviewPanel
    history={props.history}
    hash={blunderData.hash}
    type={blunderData.type}
    id={blunderData.id}
    pgn={blunderData.pgn}
    fenBefore={blunderData.fenBefore}
    lastMove={blunderData.blunderMove}
  />
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => {
  return {
    setLeft: actions => dispatch(setHeaderActionsLeftAction(actions)),
    setRight: actions => dispatch(setHeaderActionsRightAction(actions)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BlunderReviewPage);
