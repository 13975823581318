import React, { useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Container, IconButton } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { setHeaderActionsLeftAction, setHeaderActionsRightAction } from "../../store/ui.actions";
import AuthService from "../../../modules/auth/services/AuthService";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: 20,
  },
  grow: {
    flexGrow: 1,
  },
  logout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const showLeftActions = (actions) => {
  if (!actions || actions.length === 0) return null;

  return (
    <>
      {actions.map((x) => (
        <div style={{ margin: "0 20px" }}>
          <p style={{ margin: 0, fontSize: 14 }}>{x.title}</p>
          <p style={{ margin: 0, fontWeight: "bold" }}>{x.value}</p>
        </div>
      ))}
    </>
  );
};

const showRightActions = (actions) => {
  if (!actions || actions.length === 0) return null;

  return (
    <>
      {actions.map((x) =>
        x && x.to ? (
          <div style={{ margin: "0 10px" }}>
            <Link to={x.to} style={{ textDecoration: "none" }}>
              <p style={{ margin: 0, color: "white", fontSize: 14 }}>
                {x.title}
              </p>
            </Link>
          </div>
        ) : null
      )}
    </>
  );
};

const CustomaAppBar = (props) => {
  const classes = useStyles();

  const configureActions = (path) => {
    const regex = /^(\/blunder\/review.*|\/puzzle\/review.*)/;
    if(!path.match(regex)) {
      props.setLeft([]);
      // props.setRight([]);
    }
  };

  useEffect(() => {
    configureActions(props.history.location.pathname);
    props.history.listen((location) => configureActions(location.pathname));
  }, []);
  return (
    <AppBar
      {...props}
      style={{ backgroundColor: "#1D1D1D", boxShadow: "none" }}
    >
      <Container>
        <Toolbar disableGutters>
          <Link to="/" style={{ color: "white", textDecoration: "none" }}>
            <img
              src={require("../../../assets/logo.svg")}
              alt="logo"
              className={classes.logo}
            />
          </Link>
          {/* <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/" style={{ color: "white", textDecoration: 'none' }}>
              Puzzles
            </Link>
          </Typography> */}
          {showLeftActions(props.header.left)}
          <div className={classes.grow} />
          {showRightActions(props.header.right)}
          <div className={classes.logout}>
            {/* {props.user ? props.user.email : ""} */}
            <IconButton onClick={() => AuthService.logout()}>
              <img src="/icons/exit.svg"/>
            </IconButton>
            {/* {props.logout} */}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  header: state.ui.header,
});
const mapDispatchToProps = dispatch => {
  return {
    setLeft: actions => dispatch(setHeaderActionsLeftAction(actions)),
    setRight: actions => dispatch(setHeaderActionsRightAction(actions)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomaAppBar));
