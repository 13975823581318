import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

export const PgnModal = (props) => {

  const [ buttonText, setButtonText ] = useState(null);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Copy PGN</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          value={props.pgn}
          disabled
          variant="outlined"
          rowsMax={30}
          style={{
            minWidth: 300,
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(props.pgn);
            setButtonText('Copied!');
            setTimeout(() => {
              // props.onClose();
              setButtonText(null);
            }, 2000)
          }}
          variant="contained"
          color="primary"
        >
          {buttonText || 'Copy to clipboard'}
        </Button>
        <Button
          onClick={() => {
            props.onClose()
          }}
          variant="contained"
          color="secondary"
        >
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PgnModal;
