import React from 'react';
import { Route } from "react-router-dom";
import PuzzleReviewPage from './pages/puzzle-review.page';
import PuzzleAllDonePage from './pages/puzzle-all-done.page';
import AdminPuzzleReviewPage from './pages/admin-puzzle-review.page';

export const routes = [
  <Route exact path="/puzzle/all-done" component={PuzzleAllDonePage} />,
  <Route exact path="/puzzle/review" component={PuzzleReviewPage} />,
  <Route exact path="/puzzle/review/:hash" component={PuzzleReviewPage} />,
  <Route exact path="/puzzle/:hash" component={AdminPuzzleReviewPage} />,
];
