import React from 'react';
import { Route } from "react-router-dom";
import BlunderReviewPage from './pages/blunder-review.page';
import BlunderAllDonePage from './pages/blunder-all-done.page';
import AdmminBlunderReviewPage from './pages/admin-blunder-review.page';

export const routes = [
  <Route exact path="/blunder/all-done" component={BlunderAllDonePage} />,
  <Route exact path="/blunder/review" component={BlunderReviewPage} />,
  <Route exact path="/blunder/review/:hash" component={BlunderReviewPage} />,
  <Route exact path="/blunder/:id" component={AdmminBlunderReviewPage} />,
];
