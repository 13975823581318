import axios from 'axios';
import Logger from '../utils/Logger';
import * as qs from 'qs';

export default class ApiService {

  static getBaseUrl() {
    return process.env.REACT_APP_API_ENDPOINT;
  }

  /**
   * Make a call to the api
   *
   * @param {string} route Route to call
   * @param {object|null} data Data to send
   * @param {string} method HTTP method
   */
  static call(route, data, method = 'get') {

    // Build request options
    const requestOptions = {
      url: this.getBaseUrl() + '/' + route,
      method,
      headers: {},
    };

    if(method === 'get') {
      requestOptions.params = data;
    } else {
      if (data instanceof FormData) {
        requestOptions.data = data;
        requestOptions.headers['Content-Type'] = 'multipart/form-data';
      } else {
        requestOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        requestOptions.contentType = 'application/x-www-form-urlencoded';
        requestOptions.data = qs.stringify(data);
        // requestOptions.headers['Content-Type'] = 'application/json';
        // requestOptions.contentType = 'application/json';
        // requestOptions.data = JSON.stringify(data);
      }
    }

    Logger.log('request options', requestOptions);

    requestOptions.withCredentials = true;

    return new Promise((resolve, reject) => {

      axios(requestOptions)
        .then((response) => {
          Logger.log('request response', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            Logger.log('data', error.response);

            if (error.response.status === 403) {
              // TODO: Handle 403 response
            }

            if (error.response.status === 200) {
              // TODO: Handle 200 response with an error (shouldn't happen)
            }
          } else if (error.request) {
            window.location = process.env.REACT_APP_API_ENDPOINT + "/auth/login";
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }

  static async get(route, params) {
    return await this.call(
      route,
      params,
      'get'
    )
  }

  static async post(route, params) {
    return await this.call(
      route,
      params,
      'post'
    )
  }

  static async delete(route, params) {
    return await this.call(
      route,
      params,
      'delete'
    )
  }

  static async put(route, params) {
    return await this.call(
      route,
      params,
      'put'
    )
  }
}
