import React from "react";
import Chessground from "react-chessground";
import "react-chessground/dist/styles/chessground.css";
import {
  TextField,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Chess from "chess.js";
import Paper from "@material-ui/core/Paper";

const FenField = (props) => <TextField type="text" label="FEN" {...props} />;

const PgnField = (props) => (
  <TextField rows={10} type="text" label="PGN" multiline {...props} />
);

export default class TestBoard extends React.Component {
  chess = new Chess()

  state = {
  }

  pendingMove = null

  constructor(props) {
    super(props);

    this.state = {
      pgn: "",
      fen: "",
      moves: [],
      selectedIndex: -1,
      selectVisible: false,
      lastMove: null
    };
  }

  onMove = (from, to) => {
    const { chess } = this
    const moves = chess.moves({ verbose: true })
    for (let i = 0, len = moves.length; i < len; i++) { /* eslint-disable-line */
      if (moves[i].flags.indexOf("p") !== -1 && moves[i].from === from) {
        this.pendingMove = [from, to]
        this.setState({
          selectVisible: true
        })
        return
      }
    }
    if (chess.move({ from, to, promotion: "x" })) {
      this.setState({ fen: chess.fen(), lastMove: [from, to] })
      setTimeout(this.randomMove, 500)
    }
  }

  randomMove = () => {
    return;
    const { chess } = this
    const moves = chess.moves({ verbose: true })
    const move = moves[Math.floor(Math.random() * moves.length)]
    if (moves.length > 0) {
      chess.move(move.san)
      this.setState({ fen: chess.fen(), lastMove: [move.from, move.to] })
    }
  }

  promotion(e) {
    const { chess } = this
    const from = this.pendingMove[0]
    const to = this.pendingMove[1]
    chess.move({ from, to, promotion: e })
    this.setState({
      fen: chess.fen(),
      lastMove: [from, to],
      selectVisible: false
    })
    setTimeout(this.randomMove, 500)
  }

  turnColor() {
    return this.chess.turn() === "w" ? "white" : "black"
  }

  calcMovable() {
    const dests = new Map([])
    this.chess.SQUARES.forEach(s => {
      const ms = this.chess.moves({ square: s, verbose: true })
      if (ms.length) dests.set(s, ms.map(m => m.to))
    })
    return {
      free: false,
      dests,
      color: this.turnColor(),
      showDests: true
    }
  }

  _loadPgn() {
    const pgn = this.state.pgn;

    let loaded = this.chess.load_pgn(pgn);

    if (!loaded) {
      alert("Invalid PGN!!!");
      return;
    }

    const moves = this.chess.history();
    this.setState({
      fen: this.chess.fen(),
      moves: moves,
      selectedIndex: moves.length,
    });
  }

  _showState(moveIndex) {
    const moves = this.state.moves;
    var chess1 = new Chess();
    for (let i = 0; i <= moveIndex; i++) {
      chess1.move(moves[i]);
    }

    this.setState({
      selectedIndex: moveIndex,
      fen: chess1.fen(),
    });
  }

  _getInPairs() {
    const pairedMoves = [];

    this.state.moves.reduce(function (result, value, index, array) {
      if (index % 2 === 0)
        pairedMoves.push(
          array.slice(index, index + 2).map((x, ind) => ({
            index: index + ind,
            move: x,
          }))
        );
      return result;
    }, []);

    return pairedMoves;
  }

  _updateFen(value) {
    try {
      if (this.chess.validate_fen(value)) {
        this.setState({ fen: value });
      }
    } catch (e) {}
  }

  render() {
    const movesInPairs = this._getInPairs();
    const { selectVisible, fen, lastMove } = this.state

    return (
      <>
        <Grid container spacing={5} style={{ alignItems: "stretch" }}>
          {/* <Grid item sm={4}></Grid> */}
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <Box padding={0}>
              <div
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "stretch",
                }}
              >
                <div className="cg-wrap-wrapper">
                  <Chessground
                    width={584}
                    height={584}
                    turnColor={this.turnColor()}
                    movable={this.calcMovable()}
                    lastMove={lastMove}
                    fen={fen}
                    onMove={this.onMove}
                    style={{ margin: "auto" }}
                    ref={el => {
                      this.chessground = el
                    }}
                  />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Paper style={{ height: "100%" }}>
              TODO
              <TableContainer style={{ maxHeight: 800 }}>
                <Table
                  size="small"
                  component={Paper}
                  aria-label="a dense table"
                >
                  <TableBody>
                    {movesInPairs.map((rows, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        {rows.map((row, index2) => (
                          <TableCell
                            hover={true}
                            align="center"
                            style={{
                              opacity:
                                this.state.selectedIndex > row.index - 1
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            <Button
                              onClick={(e) => this._showState(row.index)}
                              color={
                                this.state.selectedIndex == row.index
                                  ? "primary"
                                  : "default"
                              }
                              variant={
                                this.state.selectedIndex == row.index
                                  ? "contained"
                                  : "text"
                              }
                            >
                              {row.move}
                            </Button>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item sm={3}>
            <Paper style={{ height: "100%" }}>TODO</Paper>
          </Grid>
        </Grid>
        <FenField
          style={{ width: "100%", marginTop: 20 }}
          value={this.state.fen}
          disabled
          // onChange={(e) => this._updateFen(e.target.value)}
        />
        <PgnField
          style={{ width: "100%", marginTop: 20 }}
          value={this.state.pgn}
          onChange={(e) => this.setState({ pgn: e.target.value })}
        />
        <Button
          variant="contained"
          style={{ marginTop: 20 }}
          onClick={(e) => this._loadPgn()}
        >
          Load PGN
        </Button>
      </>
    );
  }
}
