import React, { useEffect } from 'react';
import { ReviewService } from '../../review/services/review.service';


export const AdminPuzzleReviewPage = props => {

  const handleRedirection = async (hash) => {
    const response = await ReviewService.getPuzzleForAdmin(hash);
    if(response) {
      props.history.replace('/puzzle/review/' + response)
    }
  }

  useEffect(() => {
    const hash = props.match.params.hash;
    if(hash) {
      handleRedirection(hash);
    } else {
    }
  }, [])

  return null;
}


export default AdminPuzzleReviewPage;
