import React, { useEffect } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import _ from "lodash";

let debouncedOnChange = null;

export const RemoteIdFilter = props => {
  useEffect(() => {
    debouncedOnChange = _.debounce((event) => {
      props.onChange(event.target.value)
    }, 300);
  }, []);

  return (
    <TextField
      name="search"
      variant="outlined"
      placeholder="Remote ID"
      size="small"
      onChange={(e) => {
        e.persist();
        debouncedOnChange(e);
      }}
    ></TextField>
  )
}

export default RemoteIdFilter;
