export class Engine {
  constructor(options) {
    this.engine = this.createEngine(options);
    this.addEventListener(options.onEvaluationCB);
  }

  addEventListener(cb) {
    this.onEngineMessageWithCb = this.onEngineMessage(cb);
    this.engine.onmessage = this.onEngineMessageWithCb;
  }

  removeEventListener() {
    this.engine.removeEventListener("onmessage", this.onEngineMessageWithCb);
  }

  createEngine(options) {
    return this.createStockfish();
  }

  createStockfish() {
    var wasmSupported = typeof WebAssembly === 'object' && WebAssembly.validate(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));

    return new Worker(wasmSupported ? 'stockfish.wasm.js' : 'stockfish.js');
    // We can load Stockfish via Web Workers
    // return new Worker("stockfish.js");
  }

  onEngineMessage = (cb) => (event) => {
    if (!this.engineRunning) {
      return false;
    }

    let score = null,
        mateIn = null,
        depth = null,
        multipv = null,
        bestMove = null,
        bestLine = null;

    if (this.analysisMode) {
      const line = event && typeof event === "object" ? event.data : event;
      // eslint-disable-next-line no-console
      this.log('UCI Engine line: ', line);
      const evaluation = line.match(/score cp -?[0-9]+/g);
      if (evaluation && evaluation.length > 0) {
        score = evaluation[0].replace("score cp ", "");
        // eslint-disable-next-line no-console
        // console.warn('eval:', this.eval / 100);
      }

      const depthMatch = line.match(/depth [0-9]+/g);
      if (depthMatch && depthMatch.length > 0) {
        depth = depthMatch[0].replace("depth ", "");
      }

      // Check for mate
      const mate = line.match(/mate -?[0-9]+/g);
      if (mate && mate.length > 0) {
        mateIn = parseInt(mate[0].replace("mate ", ""));
      }

      const multipvMatch = line.match(/multipv [0-9]+/g);
      if (multipvMatch && multipvMatch.length > 0) {
        multipv = multipvMatch[0].replace("multipv ", "");
      }

      if(mateIn === 0) {
        cb(depth, 0, null, null, null, mateIn);
        return;
      }

      if(depth < 11) return;

      const bestMoveMatch = line.match(
        /bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/g
      );
      if (bestMoveMatch && bestMoveMatch.length > 0) {
        bestMove = bestMoveMatch[0].replace("bestmove ", "");
      } else {
        const bestMoveInProgress = line.match(
          /pv ([a-h][1-8])([a-h][1-8])([qrbn])?/g
        );
        const bestLineInProgress = line.match(
          /pv (([a-h][1-8])([a-h][1-8])([qrbn])? ?)+/g
        );

        if (bestMoveInProgress && bestMoveInProgress.length > 0) {
          bestMove = bestMoveInProgress[0].replace("pv ", "");
        }

        if (bestLineInProgress && bestLineInProgress.length > 0) {
          bestLine = bestLineInProgress[0].replace("pv ", "");
        }
      }

      if (cb && depth > 10) {
        cb(depth, multipv, score ? score/100 : null, bestMove, bestLine, mateIn);
      }
    }
  };

  addEventListeners() {
    this.engine.onmessage = this.onEngineMessage();
  }

  uciCmd(cmd) {
    // eslint-disable-next-line no-console
    this.log("UCI: " + cmd);
    this.engine.postMessage(cmd);
  }

  newGame() {
    this.uciCmd("ucinewgame");
  }

  stopEngine() {
    this.engineRunning = false;
    this.uciCmd("stop");
  }

  startCalculatingBestMove(fen, depth = 17) {
    this.analysisMode = true;
    this.engineRunning = true;
    this.bestMove = null;
    this.bestLine = null;
    this.uciCmd("stop");
    this.uciCmd("position fen " + fen);
    this.uciCmd("setoption name multipv value 3")
    this.uciCmd("go depth " + depth);
  }

  getBestMove() {
    return this.bestMove;
  }

  log(...props) {
    // console.log(...props);
  }
}
