import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  TableContainer,
  InputAdornment,
  Button
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ApiService from "../../../domain/services/ApiService";
import _ from "lodash";
import Checkbox from '@material-ui/core/Checkbox';

const TableCheckbox = props => <Checkbox {...props}/>

/**
 * Head receives columns property
 *
 * @param {object} props Props
 */
const Head = (props) => {
  return (
    <TableHead>
      <TableRow>
        {props.multiselect ? <TableCell><TableCheckbox checked={isCheckedAll(props.data, props.selected)} onChange={event => props.handleSelectionChange(props.data, event.target.checked)}/></TableCell> : null}
        {props.columns.map((column) => (
          <TableCell {...(column[2] ? column[2] : {})}>{column[0]}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

/**
 * Body receives data and columns
 */
const Body = (props) => {
  return (
    <TableBody>
      {props.data.map((row) => (
        <TableRow key={row.name}>
          {props.multiselect ? (
            <TableCell>
              <TableCheckbox
                checked={isChecked(row, props.selected)}
                onChange={event => props.handleSelectionChange(row, event.target.checked)}
              />
            </TableCell>
          ) : null}
          {props.columns.map((column) => {
            let field = column[1];

            let display = "-";

            if (typeof field === "function") {
              display = field(row);
            } else {
              display = _.get(row, field);
            }

            if (field) {
              return <TableCell>{display}</TableCell>;
            }

            return <TableCell />;
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

const MultiselectActions = props => {

  if(!props.multiselect) return null;

  if(!props.multiselectActions || props.multiselectActions.length === 0) return null;

  if(!props.selected || props.selected.length === 0) return null;

  const fields = [];

  props.multiselectActions.map((x, index) => {
    fields.push(
      <div style={{ marginLeft: 5 }} key={"action" + index}>
        {x.render ? x.render() : <Button variant="contained" color="primary" onClick={e => x.onSelect(props.selected)}>{x.label}</Button>}
      </div>
    );
  })

  return fields;
}

const isCheckedAll = (items, selectedItems) => {
  return _.isEqual(
    items.map(x => x.id).sort(),
    selectedItems.map(x => x.id).sort()
  )
}

const isChecked = (row, selectedItems) => {
  if(!selectedItems) return false;

  if(selectedItems === 'all') return true;

  if(selectedItems.find(x => x.id == row.id)) return true;

  return false;
}

let debouncedOnChange = null;

export const CrudTable = (props) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({});
  const [status, setStatus] = useState("loading");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    fetchData();
    debouncedOnChange = _.debounce((event) => {
      setParameter('customFilter.search', event.target.value, params);
    }, 300);
  }, []);

  // TODO: We need a better solution for this
  // Probably convert the component to a class component and use a this.refresh() method
  useEffect(() => {
    fetchData(page, params);
  }, [props.refresh, params]);

  const setParameter = (path, value, overrideParams = null) => {
    let updatedParams = {...(overrideParams ? overrideParams : params)};
    _.set(updatedParams, path, value);
    setParams(updatedParams);
  }

  const fetchData = async (page = 1, params = {}) => {
    try {
      let data = await loadItems({
        url: props.resource,
        page: page,
        params: {
          ...params,
          ...(props.params ? props.params : {})
        }
      });
      setData(data.data);
      setPagination({
        count: data.count,
        total: data.total,
        page: data.page,
        pageCount: data.pageCount,
      });
    } catch (e) {
      setStatus("error");
    }
  };

  const goToPage = (page) => {
    fetchData(page, params);
  };

  const onFilterChange = (filterName, value) => {
    const customFilter = params.customFilter || {};
    customFilter[filterName] = value;
    setParams({ ...params, customFilter: customFilter });
  };

  const renderAdditionalFilterFields = () => {
    if (!props.additionalFilterFields) return null;

    const elements = [];

    props.additionalFilterFields.map((field) => {
      elements.push(
        <div style={{ marginRight: 5 }}>
          {field.render({
            onFilterChange,
            params
          })}
        </div>
      );
    });

    return elements;
  };

  const handleSelectionChange = (item, isSelected) => {

    if(item === 'all') {
      setSelected(isSelected ? 'all' : []);
      return;
    }

    let newSelected = [];
    if(selected && selected.length) {
      newSelected = [...selected];
    }

    const items = _.isArray(item) ? item : [item];

    for(let i = 0; i < items.length; i++) {
      if(!isSelected) {
        newSelected = newSelected.filter(x => x.id != items[i].id);
      } else {
        newSelected = newSelected.filter(x => x.id != items[i].id);
        newSelected.push(items[i]);
      }
    }

    setSelected(newSelected);
  }

  // TODO: Add sorting

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          margin: "10px 0",
        }}
      >
        {renderAdditionalFilterFields()}
        <TextField
          name="search"
          variant="outlined"
          size="small"
          onChange={(e) => {
            e.persist();
            debouncedOnChange(e);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <img src="/icons/search.svg" />
              </InputAdornment>
            ),
          }}
        ></TextField>
        <MultiselectActions
          multiselect={props.multiselect}
          multiselectActions={props.multiselectActions}
          selected={selected}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <Head
            data={data}
            status={status}
            columns={props.columns}
            multiselect={props.multiselect}
            selected={selected}
            handleSelectionChange={handleSelectionChange}
          />
          <Body
            data={data}
            status={status}
            columns={props.columns}
            multiselect={props.multiselect}
            selected={selected}
            handleSelectionChange={handleSelectionChange}
            multiselectActions={props.multiselectActions}
          />
        </Table>
        {pagination.page ? (
          <Pagination
            style={{ margin: "15px 0" }}
            count={pagination.pageCount}
            page={pagination.page}
            onChange={(event, page) => goToPage(page)}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};

const loadItems = async (props) => {
  return await ApiService.get(props.url, {
    page: props.page ? props.page : 1,
    search: _.get(props, 'params.customFilter.search', ""),
    ...(props.params ? props.params : {}),
  });
};
